// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails"
import "../controllers"
import Awesomplete from "awesomplete"

document.addEventListener("click", function (e) {
  if (e.target?.matches(".film-search-trigger,.film-search-trigger *")) {
    e.preventDefault()
    TIFFR.search.show()
  }
})

document.addEventListener("click", function (e) {
  if (e.target?.matches("#film-search-close,#film-search-close *")) {
    e.preventDefault()
    TIFFR.search.hide()
  }
})

document.addEventListener("awesomplete-select", function (e) {
  e.preventDefault()

  let href =
    e.origin.getAttribute("href") ||
    e.origin.querySelector("a").getAttribute("href")

  if (window.location.pathname !== href) {
    Turbo.visit(href)
  } else {
    TIFFR.search.hide()
  }
})

window.addEventListener("keyup", function (e) {
  let filmSearch = document.querySelector("#film-search")

  if (filmSearch.classList.contains("is-searching")) {
    switch (e.key) {
      case "Escape":
        filmSearch.classList.remove("is-searching")
        document.querySelector("body").classList.remove("is-searching")
        break
    }
  } else {
    switch (e.key) {
      case "/":
        e.preventDefault()
        TIFFR.search.show()
        break
    }
  }
})

// Film Search Logic (2022)
// Would be great to replace this logic with Stimulus

TIFFR.search = {
  corpus: null,

  show: function () {
    ;["#film-search", "body"].forEach((selector) => {
      let element = document.querySelector(selector)
      element.classList.add("is-searching")
    })

    let searchInput = document.querySelector("#film-search-input")
    if (searchInput) searchInput.focus()
  },

  hide: function () {
    let searchInput = document.querySelector("#film-search-input")
    searchInput.value = ""
    searchInput.blur()

    ;["#film-search", "body"].forEach((selector) => {
      let element = document.querySelector(selector)
      element.classList.remove("is-searching")
    })
  },

  init: function (list) {
    let searchInput = document.getElementById("film-search-input")

    new Awesomplete(searchInput, {
      list: list,
      item: (text) => {
        let resultElement = document.createElement("li")
        let resultLink = document.createElement("a")

        resultElement.setAttribute("class", "film-search-result")

        resultLink.setAttribute("href", "/shows/" + text.value)
        resultLink.innerHTML = text.label

        resultElement.append(resultLink)

        return resultElement
      },
      autoFirst: true,
    })
  },
}

document.addEventListener("turbo:load", () => {
  if (TIFFR.festivalSubdomain) {
    if (!TIFFR.search.corpus) {
      fetch("/api/" + TIFFR.festivalSubdomain + "/shows.json")
        .then((response) => response.json())
        .then((results) => {
          TIFFR.search.corpus = results.map((show) => {
            return { label: show.title, value: show.slug }
          })

          return TIFFR.search.corpus
        })
        .then((shows) => TIFFR.search.init(shows))
    } else {
      TIFFR.search.init(TIFFR.search.corpus)
    }
  }
})
